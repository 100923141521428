import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { UploadDropZoneDialog } from '@fingo/lib/components/dialogs';
import { openUrlAndDownload } from '@fingo/lib/helpers';
import { useSnackBars } from '@fingo/lib/hooks';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Add from '@mui/icons-material/Add';
import LocationCity from '@mui/icons-material/LocationCity';
import PersonAdd from '@mui/icons-material/PersonAdd';
import { DownloadExcelIcon } from '@fingo/lib/components/icons';
import AssignExternalLeadsDialog from './AssignExternalLeadsDialog';
import AddExternalLeadsDialog from './AddExternalLeadsDialog';
import { UPLOAD_EXTERNAL_LEADS_EXCEL, GET_EXTERNAL_LEADS_TEMPLATE, GET_EXTERNAL_LEADS, UPLOAD_CONTACTS_EXCEL } from '../../../graphql/external-leads';

const ExternalLeadsActions = (props) => {
  const {
    selectedExternalLeadIds,
    setSelectedExternalLeadIds,
  } = props;
  const [uploadType, setUploadType] = useState('');
  const [uploadMenuAnchorEl, setUploadMenuAnchorEl] = useState(null);
  const [selectedExternalLeads, setSelectedExternalLeads] = useState([]);
  const [openAddExternalLeadsDialog, setOpenAddExternalLeadsDialog] = useState(false);
  const [openUploadExcelDialog, setOpenUploadExcelDialog] = useState(false);
  const [openAssignExternalLeadsDialog, setOpenAssignExternalLeadsDialog] = useState(false);
  const [files, setFiles] = useState([{ name: '' }]);
  const { addAlert } = useSnackBars();

  useQuery(
    GET_EXTERNAL_LEADS,
    {
      skip: !selectedExternalLeadIds?.length,
      variables: {
        masterEntityId_In: selectedExternalLeadIds,
      },
      onCompleted: ({ getExternalLeads }) => {
        setSelectedExternalLeads(getExternalLeads.edges.map((edge) => edge.node));
      },
    },
  );

  const [downLoadExcelTemplate] = useLazyQuery(
    GET_EXTERNAL_LEADS_TEMPLATE,
    {
      onCompleted: (data) => openUrlAndDownload(data.getExternalLeadsTemplate, 'excel_de_ejemplo.xlsx'),
      notifyOnNetworkStatusChange: true,
    },
  );
  const [
    uploadExternalLeadsExcel,
    { loading: uploadExternalLeadsExcelLoading },
  ] = useMutation(
    UPLOAD_EXTERNAL_LEADS_EXCEL,
    {
      variables: { file: files[0] },
      onCompleted: ({ uploadExternalLeadsExcel: { newLeads } }) => {
        setFiles([{ name: '' }]);
        setOpenUploadExcelDialog(false);
        addAlert({
          id: 'new-leads',
          message: `Se añadieron ${newLeads.length} leads nuevos`,
        });
      },
      onError: () => addAlert({ id: 'upload-lead-error', message: 'Ha ocurrido un error' }),
      refetchQueries: [GET_EXTERNAL_LEADS],
    },
  );
  const [
    uploadContactsExcel,
    { loading: uploadContactsExcelLoading },
  ] = useMutation(
    UPLOAD_CONTACTS_EXCEL,
    {
      variables: { file: files[0] },
      onCompleted: ({ uploadContactsExcel: { newContacts } }) => {
        setFiles([{ name: '' }]);
        setOpenUploadExcelDialog(false);
        addAlert({
          id: 'new-contacts',
          message: `Se añadieron ${newContacts.length} contactos nuevos`,
        });
      },
      onError: () => addAlert({ id: 'upload-contact-error', message: 'Ha ocurrido un error' }),
    },
  );
  const open = Boolean(uploadMenuAnchorEl);
  const handleClick = (event) => {
    setUploadMenuAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setUploadMenuAnchorEl(null);
  };
  const manageExcelDialog = (type) => {
    handleClose();
    setUploadType(type);
    setOpenUploadExcelDialog(true);
  };

  const manageExcelUpload = () => {
    if (uploadType === 'leads') {
      uploadExternalLeadsExcel();
    } else {
      uploadContactsExcel();
    }
    setUploadType('');
  };

  return (
    <>
      <Button
        color="primary"
        size="small"
        variant="contained"
        onClick={() => setOpenAssignExternalLeadsDialog(true)}
        disabled={!selectedExternalLeadIds?.length}
      >
        Asignar leads a un ejecutivo
      </Button>
      <Tooltip title="Agregar">
        <IconButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <Add />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={uploadMenuAnchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => manageExcelDialog('contacts')}>
          <PersonAdd color="gray" sx={{ mr: 2 }} />
          Subir contactos
        </MenuItem>
        <MenuItem onClick={() => setOpenAddExternalLeadsDialog(true)}>
          <LocationCity color="gray" sx={{ mr: 2 }} />
          Añadir un lead externo
        </MenuItem>
        <MenuItem onClick={() => manageExcelDialog('leads')}>
          <DownloadExcelIcon color="gray" />
          <Typography ml={2}>
            Subir archivo de leads
          </Typography>
        </MenuItem>
      </Menu>
      <UploadDropZoneDialog
        open={openUploadExcelDialog}
        setOpen={setOpenUploadExcelDialog}
        files={files}
        setFiles={setFiles}
        message={`Carga el excel con los nuevos ${uploadType} (Formato XLSX o CSV - máximo 10Mb)`}
        onCompleted={manageExcelUpload}
        loading={uploadExternalLeadsExcelLoading || uploadContactsExcelLoading}
        downloadTemplate={downLoadExcelTemplate}
      />
      <AssignExternalLeadsDialog
        open={openAssignExternalLeadsDialog}
        setOpen={setOpenAssignExternalLeadsDialog}
        selectedExternalLeads={selectedExternalLeads}
        setSelectedExternalLeads={setSelectedExternalLeadIds}
      />
      <AddExternalLeadsDialog
        open={openAddExternalLeadsDialog}
        setOpen={setOpenAddExternalLeadsDialog}
      />
    </>
  );
};

ExternalLeadsActions.propTypes = {
  setSelectedExternalLeadIds: PropTypes.func.isRequired,
  selectedExternalLeadIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ExternalLeadsActions;
