import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { LoadingIconButton } from '@fingo/lib/components/buttons';
import FingoStack from '@fingo/lib/components/stacks/FingoStack';
import Button from '@mui/material/Button';
import Cached from '@mui/icons-material/Cached';
import useGetExecutives from '@fingo/lib/hooks/useGetExecutives';
import ExecutiveProportionsDialog from './ExecutiveProportionsDialog';
import { UPDATE_USER_EXECUTIVE_ASSIGNATION_PROPORTION } from '../../../graphql/executives';

const ExecutivesActions = () => {
  const [openExecutiveProportionsDialog, setOpenExecutiveProportionsDialog] = useState(false);
  const { executives, loading, refetch } = useGetExecutives();
  const [updateUserExecutiveProportions] = useMutation(
    UPDATE_USER_EXECUTIVE_ASSIGNATION_PROPORTION,
  );

  const handleUpdateExecutiveProportions = (assignationType, selectedExecutives) => {
    const assignationKey = assignationType.includes('login') ? 'loginActiveAssignation' : 'clickActiveAssignation';
    const input = selectedExecutives
      .filter((executive) => executive.active && executive[assignationKey])
      .map((executive) => ({
        id: executive.user.id,
        assignationProportion: executive[assignationType],
      }));
    updateUserExecutiveProportions({ variables: { assignationType, input } });
  };

  return (
    <>
      <ExecutiveProportionsDialog
        open={openExecutiveProportionsDialog}
        executives={executives}
        handleUpdateExecutive={handleUpdateExecutiveProportions}
        handleClose={() => setOpenExecutiveProportionsDialog(false)}
      />
      <FingoStack>
        <LoadingIconButton
          onClick={refetch}
          sx={{ mr: { xs: '0', md: 'auto' } }}
          loading={loading}
          color="gray"
        >
          <Cached sx={{ display: { xs: 'none', md: 'inherit' } }} />
        </LoadingIconButton>
        <Button
          id="executive-propotions-dialog"
          size="small"
          color="primary"
          variant="contained"
          onClick={() => setOpenExecutiveProportionsDialog(true)}
        >
          Proporción ejecutivos
        </Button>
      </FingoStack>
    </>
  );
};

export default ExecutivesActions;
