import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import FormDataGrid from '@fingo/lib/components/dataGrids/FormDataGrid';
import { useSnackBars } from '@fingo/lib/hooks';
import { LoadingButton } from '@mui/lab';
import { FORM_DATA_GRID_COLUMNS } from '../../../constants/external-leads';
import { ADD_EXTERNAL_LEADS, GET_EXTERNAL_LEADS } from '../../../graphql/external-leads';

const AddExternalLeadsDialog = ({ open, setOpen }) => {
  const [newExternalLeads, setNewExternalLeads] = useState([]);
  const disabled = !newExternalLeads.length || newExternalLeads.some((newExternalLead) => (
    Object.values(newExternalLead).some((value) => value === '')
  ));
  const { addAlert } = useSnackBars();
  const [addExternalLeads, { loading }] = useMutation(
    ADD_EXTERNAL_LEADS,
    {
      variables: {
        externalLeads: newExternalLeads.map((lead) => {
          const {
            masterEntityName: name,
            masterEntityRut,
            sourceName: source,
            typeName: type,
          } = lead;
          return {
            name,
            rut: masterEntityRut.split('-')[0],
            source,
            type,
          };
        }),
      },
      onCompleted: ({ addExternalLeads: { newLeads } }) => {
        setNewExternalLeads([]);
        setOpen(false);
        addAlert({
          id: 'new-leads',
          message: `Se añadieron ${newLeads.length} leads nuevos`,
        });
      },
      onError: () => addAlert({ id: 'add-external-lead-error', message: 'Ha ocurrido un error con la subida de leads' }),
      refetchQueries: [GET_EXTERNAL_LEADS],
    },
  );

  return (
    <FingoDialog
      open={open}
      handleClose={() => setOpen(false)}
      title="Añadir nuevos leads externos"
      dialogActionButton={(
        <LoadingButton
          color="primary"
          onClick={addExternalLeads}
          disabled={disabled}
          loading={loading}
        >
          Añadir leads
        </LoadingButton>
      )}
      maxWidth="md"
      fullWidth
    >
      <FormDataGrid
        isBackoffice
        rows={newExternalLeads}
        setRows={setNewExternalLeads}
        columns={FORM_DATA_GRID_COLUMNS}
        originalRows={newExternalLeads}
        density="compact"
      />
    </FingoDialog>
  );
};

AddExternalLeadsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default AddExternalLeadsDialog;
