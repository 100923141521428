import { useMutation } from '@apollo/client';
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { PaperHeader } from '@fingo/lib/components/headers';
import { useBooleanState } from '@fingo/lib/hooks';
import useGetExecutives from '@fingo/lib/hooks/useGetExecutives';
import EditIcon from '@mui/icons-material/Edit';
import { GridActionsCellItem } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { DATA_GRID_COLUMNS, HEADER_TITLE } from '../../constants/executives';
import { UPDATE_USER_EXECUTIVE } from '../../graphql/executives';
import EditExecutiveDialog from './executives/EditExecutiveDialog';
import ExecutivesActions from './executives/ExecutivesActions';

const Executives = () => {
  const [selectedExecutive, setSelectedExecutive] = useState(null);
  const [openEditDialog, toggleDialog] = useBooleanState(false);
  const { executives, loading } = useGetExecutives();
  const [updateUserExecutive] = useMutation(UPDATE_USER_EXECUTIVE);

  const columns = [
    ...DATA_GRID_COLUMNS,
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Editar',
      flex: 2,
      getActions: (params) => [
        <GridActionsCellItem
          key={params.row.id}
          icon={<EditIcon />}
          label="Edit Executive"
          onClick={() => {
            setSelectedExecutive(params.row);
            toggleDialog();
          }}
        />,
      ],
    },
  ];

  return (
    <>
      <PaperHeader viewTitle={HEADER_TITLE} />
      <ExecutivesActions />
      <FingoDataGrid
        rows={executives}
        columns={columns}
        loadingWithSkeleton={loading}
        getRowId={(row) => row.user.id}
        sx={{
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            overflow: 'hidden',
            lineHeight: '20px',
            whiteSpace: 'normal',
          },
        }}
        headerHeight={70}
        density="compact"
      />
      <EditExecutiveDialog
        open={openEditDialog}
        executive={selectedExecutive}
        handleUpdateExecutive={(variables) => updateUserExecutive({ variables })}
        handleClose={() => {
          setSelectedExecutive(null);
          toggleDialog();
        }}
      />
    </>
  );
};

export default Executives;
